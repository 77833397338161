import Glide, { Controls, Breakpoints, Autoplay } from '@glidejs/glide/dist/glide.modular.esm';

export class Testimonials {
    constructor() {
        if (!document.querySelector('.testimonials')) {
            return;
        }

        this.imagesSelector = '.testimonials-images';
        this.contentSelector = '.testimonials-content';

        this.$imagesEl = document.querySelector(this.imagesSelector);
        this.$contentEl = document.querySelector(this.contentSelector);

        this.imagesGlide = null;
        this.contentGlide = null;

        this.init();
    }

    init() {
        this.imagesGlide = new Glide(this.$imagesEl, { autoplay: 7000 });
        this.contentGlide = new Glide(this.$contentEl, { autoplay: 7000 });

        this.imagesGlide.on('run', () => this.contentGlide.go('=' + this.imagesGlide.index));

        this.imagesGlide.mount({ Controls, Breakpoints, Autoplay });
        this.contentGlide.mount({ Controls, Autoplay });
    }
}
