<template>
    <a href @click.prevent="open = true" class="team-member block relative group py-2 md:py-3 lg:py-4 hover:bg-blue-lightest transition-all px-2 md:px-3 lg:px-5">
        <div class="">
            <div class="w-full pb-[100%] max-h-0 relative mb-2 lg:mb-5">
                <img :src="member.image.url" class="block absolute w-full h-full top-0 left-0 object-cover object-top" />
            </div>
            <div class="headline-sm text-blue-dark group-hover:text-blue transition-colors">{{ member.name }}</div>
            <div class="text-blue font-bold mt-0 md:mt-1 text-sm md:text-base">{{ member.position }}</div>
        </div>

        <div class="absolute bg-blue-lighter h-[1px] bottom-0 left-2 md:left-3 lg:left-4 right-2 md:right-3 lg:right-4 group-hover:left-0 group-hover:right-0 transition-all"></div>
    </a>

    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-20" @close="open = false" :initial-focus="null">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-white bg-opacity-95 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogPanel class="relative transform overflow-hidden px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:p-6">
                            <TeamMemberBio @panel:close="open = false" />
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { provide, ref } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import TeamMemberBio from './MemberBio.vue';

const props = defineProps({
    member: {
        type: Object,
        required: true,
    },
});

provide('member', props.member);

const open = ref(false);
</script>
