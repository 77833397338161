import '../styles/tekplan.css';
import { createApp } from 'vue';
import InlineSvg from 'vue-inline-svg';

import components from './components';

import { Testimonials } from './modules/testimonials';
import { IntroGallery } from './modules/intro-gallery';

const app = createApp({
    components,
    mounted() {
        new Testimonials;
        new IntroGallery;
    }
});


app.component('inline-svg', InlineSvg);
app.mount('#app');
