<template>
    <div class="absolute z-20 w-full">
        <nav class="mx-auto relative flex max-w-7xl items-center justify-between py-8 lg:py-16 p-6 lg:px-8" aria-label="Global">
            <div class="flex lg:flex-1">
                <a href="/" class="-m-1.5 p-1.5 navbar-logo outline-none">
                    <span class="sr-only">Tekplan Solutions</span>
                    <inline-svg src="/app/themes/tekplan/images/logo.svg" class="h-16 lg:h-28 w-auto"></inline-svg>
                </a>
            </div>
            <div v-if="canToggle" class="flex lg:hidden">
                <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 outline-none" @click="mobileMenuOpen = true">
                    <span class="sr-only">Open main menu</span>
                    <Bars3Icon class="h-12 w-12 text-blue" aria-hidden="true" />
                </button>
            </div>
            <div class="hidden lg:flex lg:gap-x-12">
                <a v-for="item in items" :key="'nav-item-' + item.id" :href="item.url" class="text-blue-dark hover:text-blue text-lg transition-colors" :class="{ 'border-b border-blue': item.active }">{{ item.label }}</a>
            </div>

            <div class="hidden lg:block fixed right-0 top-8 pr-6 lg:pr-8">
                <a href="mailto:info@tekplansolutions.com" class="btn btn-sm btn-inverse hover:bg-white/90 uppercase tracking-wide">Contact Tekplan Solutions</a>
            </div>
        </nav>

        <TransitionRoot :show="mobileMenuOpen" as="template">
            <Dialog v-if="canToggle" as="div" class="lg:hidden relative z-30" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
                <TransitionChild enter="transition-opacity ease-linear duration-200" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 z-10 bg-[black]/70 bg-blend-multiply" />
                </TransitionChild>
                <TransitionChild
                    enter="transition ease-in-out duration-200 transform"
                    enter-from="-translate-x-full"
                    enter-to="translate-x-0"
                    leave="transition ease-in-out duration-200 transform"
                    leave-from="translate-x-0"
                    leave-to="-translate-x-full"
                >
                    <DialogPanel class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-blue-dark px-0 py-0 sm:max-w-sm shadow-xl shadow-black/40">
                        <div class="flex flex-col h-full">
                            <div class="mx-auto w-full flex items-center justify-between py-8 lg:py-16 p-6 lg:px-8">
                                <div class="flex lg:flex-1">
                                    <a href="/" class="-m-1.5 p-1.5 nav-logo">
                                        <span class="sr-only">Tekplan Solutions</span>
                                        <inline-svg src="/app/themes/tekplan/images/logo.svg" class="h-16 lg:h-28 w-auto"></inline-svg>
                                    </a>
                                </div>
                                <div class="flex lg:hidden">
                                    <button type="button" class="-m-2.5 rounded-md p-2.5 text-blue" @click="mobileMenuOpen = false">
                                        <span class="sr-only">Close menu</span>
                                        <XMarkIcon class="h-12 w-12" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>

                            <div class="grow w-full mt-8 text-lg sm:text-xl">
                                <div class="flex flex-col gap-y-5 sm:gap-y-6 h-full justify-between">
                                    <div class="flex flex-col sm:gap-y-2">
                                        <div v-for="item in items" :key="'mnav-item-' + item.id" class="w-full pr-6 text-white">
                                            <a :href="item.url" class="block mr-4 hover:text-blue py-3 px-6 transition-colors w-full" :class="{ 'bg-blue rounded-r-lg text-blue-dark hover:text-blue-dark': item.active }">{{ item.label }}</a>
                                        </div>

                                        <div class="mt-12 ml-6">
                                            <a href="/contact/#contact" class="btn btn-sm uppercase text-white">Contact Us</a>
                                        </div>
                                    </div>

                                    <div class="flex flex-col gap-y-1 border-t border-blue-light/25 py-8 text-base">
                                        <div v-for="termsItem in termsItems" class="px-6">
                                            <a :href="termsItem.url">{{ termsItem.label }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </TransitionChild>
            </Dialog>
        </TransitionRoot>
    </div>
</template>

<script setup>
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/solid';
import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue';
import { ref } from 'vue';

defineProps({
    items: {
        type: Array,
        required: true,
    },

    active: {
        type: Number,
        default: null,
    },

    canToggle: {
        type: Boolean,
        default: false,
    },

    termsItems: {
        type: Array,
        required: true,
    },
});

const mobileMenuOpen = ref(false);
</script>
