import Glide, { Controls, Breakpoints, Autoplay } from '@glidejs/glide/dist/glide.modular.esm';

export class IntroGallery {
    constructor() {
        this.selector = '.intro-gallery';

        if (!document.querySelector(this.selector)) {
            return;
        }

        this.$el = document.querySelector(this.selector);
        this.glide = null;

        this.init();
    }

    init() {
        this.glide = new Glide(this.$el, { autoplay: 5000 });
        this.glide.mount({ Controls, Breakpoints, Autoplay });
    }
}
