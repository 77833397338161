<template>
    <div class="flex flex-col md:flex-row md:gap-x-12 lg:gap-x-16">
        <div class="md:hidden flex justify-end mb-2">
            <button type="button" class="text-blue hover:text-blue-dark focus:outline-none transition-colors" @click="$emit('panel:close')">
                <span class="sr-only">Close</span>
                <inline-svg src="/app/themes/tekplan/images/x-circle.svg" class="w-12 h-12"></inline-svg>
            </button>
        </div>

        <div class="flex flex-col sm:flex-row gap-x-8 sm:items-center mb-8 md:hidden">
            <div class="w-1/3">
                <div class="w-full pb-[100%] max-h-0 relative mb-3 sm:mb-0">
                    <img :src="member.image.url" class="block absolute w-full h-full top-0 left-0 object-cover" />
                </div>
            </div>
            <div class="w-2/3">
                <DialogTitle as="h3" class="headline-sm text-blue-dark">{{ member.name }}</DialogTitle>
                <div class="text-blue font-bold">{{ member.position }}</div>

                <hr class="text-blue-lighter my-2 sm:my-6" />

                <div class="flex flex-col gap-y-2 items-start text-xs">
                    <a v-if="member.email" :href="emailHref" class="font-bold text-gray-dark hover:text-blue-dark transition-colors outline-none">{{ member.email }}</a>
                    <a v-if="member.phone" :href="phoneHref" class="font-bold text-gray-dark hover:text-blue-dark transition-colors outline-none">{{ member.phone }}</a>
                </div>
            </div>
        </div>

        <div class="hidden md:basis-1/3 max-w-[350px]">
            <div class="w-full pb-[100%] max-h-0 relative mb-5">
                <img :src="member.image.url" class="block absolute w-full h-full top-0 left-0 object-cover" />
            </div>

            <div class="flex flex-col gap-y-5 items-start">
                <a v-if="member.email" :href="emailHref" class="font-bold text-blue hover:text-blue-dark transition-colors outline-none">{{ member.email }}</a>
                <a v-if="member.phone" :href="phoneHref" class="font-bold text-blue hover:text-blue-dark transition-colors outline-none">{{ member.phone }}</a>
            </div>
        </div>
        <div class="basis-2/3">
            <div class="hidden md:flex justify-between">
                <div class="block">
                    <DialogTitle as="h3" class="headline text-blue-dark">{{ member.name }}</DialogTitle>
                    <div class="text-blue font-bold md:mt-1">{{ member.position }}</div>
                </div>
                <div class="hidden md:block pt-2">
                    <button type="button" class="text-blue hover:text-blue-dark focus:outline-none transition-colors" @click="$emit('panel:close')">
                        <span class="sr-only">Close</span>
                        <inline-svg src="/app/themes/tekplan/images/x-circle.svg" class="w-12 h-12"></inline-svg>
                    </button>
                </div>
            </div>

            <div class="mt-2 md:mt-10" v-html="member.bio"></div>
        </div>
    </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { DialogTitle } from '@headlessui/vue';

const member = inject('member');

const emailHref = computed(() => 'mailto:' + member.email);
const phoneHref = computed(() => 'tel:' + member.phone);
</script>
